import * as React from 'react';

import Box from '@mui/joy/Box';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
import CssBaseline from '@mui/joy/CssBaseline';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import { CssVarsProvider } from '@mui/joy/styles';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

import Header from '../../Components/Header.tsx';
import OrderTable from './components/OrderTable.tsx';


export default function BaseSetup() {
	return (
		<CssVarsProvider disableTransitionOnChange>
			<CssBaseline />
			<Box
				sx={{
					height:'minmax(57px, 70px)',
					bgcolor: 'background.surface',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					borderBottom: '1px solid',
					borderColor: 'divider',
					position: 'sticky',
					top: 0,
					zIndex: 1100,
				}}
			>
				<Header/>
			</Box>
			<Box sx={{ display: 'flex', minHeight: '90vh' }}>
				<Box
					component="main"
					className="MainContent"
					sx={{
						px: { xs: 2, md: 6 },
						pb: { xs: 2, sm: 2, md: 3 },
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
						minWidth: 0,
						height: '90dvh',
						gap: 1,
					}}
				>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Breadcrumbs
							size="sm"
							aria-label="breadcrumbs"
							separator={<ChevronRightRoundedIcon/>}
							sx={{ pl: 0, mx: 2 }}
						>
							<Link
								underline="none"
								color="neutral"
								href="/"
								aria-label="Home"
							>
								<HomeRoundedIcon />
							</Link>
							<Link
								underline="none"
								color="neutral"
								href="/Store"
								aria-label="Home"
							>
								<Typography fontWeight={500} fontSize={12}>
									Store
								</Typography>
							</Link>
							<Typography color="primary" fontWeight={500} fontSize={12}>
								{window.location.pathname.split('/').pop()}
							</Typography>
						</Breadcrumbs>
					</Box>
					<OrderTable />
				</Box>
			</Box>
		</CssVarsProvider>
	);
}