import * as React from 'react';
import { useState} from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/joy/Box';
//import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Button from '@mui/joy/Button';
//import Checkbox from '@mui/joy/Checkbox';
import Typography from '@mui/joy/Typography';
import CssBaseline from '@mui/joy/CssBaseline';
import FormControl from '@mui/joy/FormControl';
import GlobalStyles from '@mui/joy/GlobalStyles';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import IconButton, { IconButtonProps } from '@mui/joy/IconButton';

import PassIcon from '@mui/icons-material/Lock';
import LoginIcon from '@mui/icons-material/Person';
import OpenEyeIcon from '@mui/icons-material/Visibility';
import CloseEyeIcon from '@mui/icons-material/VisibilityOff';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';

import URL from '../URl';

let login;
let password;

interface FormElements extends HTMLFormControlsCollection {
	login: HTMLInputElement;
	password: HTMLInputElement;
	persistent: HTMLInputElement;
}

interface SignInFormElement extends HTMLFormElement {
  	readonly elements: FormElements;
}

function ColorSchemeToggle(props: IconButtonProps) {
	const { onClick, ...other } = props;
	const { mode, setMode } = useColorScheme();
	const [mounted, setMounted] = React.useState(false);
	React.useEffect(() => {
		setMounted(true);
	}, []);
	if (!mounted) {
		return <IconButton size="sm" variant="outlined" color="neutral" disabled />;
	}

	return (
		<IconButton
		id="toggle-mode"
		size="sm"
		color="neutral"
		aria-label="toggle light/dark mode"
		{...other}
		onClick={(event) => {
			if (mode === 'light') {
			setMode('dark');
			} else {
			setMode('light');
			}
			onClick?.(event);
		}}
		>
		{mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
		</IconButton>
	);
}

function Logo() {
    const colorScheme = useColorScheme();
    const imageSource = colorScheme.mode === 'light' ? 'images/logo.svg' : 'images/logo_white.svg';

    return (
        <img width='260' src={imageSource} alt="" />
    );
}

export default function JoySignInSideTemplate() {
	const [selectedServer, setSelectedServer] = useState<string>('');
	const [showLoginForm, setShowLoginForm] = useState(true);
	const [showPassword, setShowPassword] = useState(false);
	const [servers, setServers] = useState<string[]>([]);
	const navigate = useNavigate();

	//Проверка наличия cookie "asid"
	React.useEffect(() => {
		const asidCookie = document.cookie.split('; ').find((cookie) => cookie.startsWith('asid='));
		if (asidCookie) {
			navigate('/');
		}
	}, []);

	// Функция для выполнения запроса к Sessions
	const performSessionRequest = (login, password, storageId) => {
		
		return fetch(`${URL}/Sessions`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				login,
				password,
				storageId,
			}),
			credentials: 'include'
		})
		.then((response) => 
			{
				if (!response.ok){
					if (response.status === 401) {
						alert("Wrong login or password!");
						throw new Error("Wrong login or password");
					}
				}
				return response.json();
			})
		.then((data) => {
		// Проверка успешности выполнения запроса
			if (data) {
				navigate('/');
			} else {
				alert("Ошибка");
			}
		})
		.then(()=>{
			SelfRequest();
		})
		.then(()=>{
			//Временный костыль для починки темы на главной странице
			localStorage.setItem('joy-mode', 'light');
		})
		.catch((error) => {
			console.error('Ошибка при получении данных:', error);
		});
	};

	// Функция проверки login
	const checkLogin = (login,password) => {
		if(login){
		if (login.includes('/')){
			const alias = login.split('/')[0];
			fetch(`${URL}/Companies/${alias}/storageList`)
			.then((response) => response.json())
			.then((data) => {checkCountOfStorege(data,login,password)})
			.catch((error) => console.error('Ошибка запроса списка серверов:', error));
		} else {
			fetch(`${URL}/Companies/default/storageList`)
			.then((response) => response.json())
			.then((data) => {checkCountOfStorege(data,login,password);})
			.catch((error) => console.error('Ошибка запроса списка серверов:', error));
		}
		}
		return;
	};

	// Функция проверки количества серверов
	const checkCountOfStorege = (data,login,password) => {
		let storageId;
		if (data.length === 1) {
			// Если только один storage, выполняем запрос к конечной точке Sessions
			storageId = data[0].id;
			performSessionRequest(login,password, storageId)
			.catch((error) => console.error('Ошибка при получении данных:', error));
		} else {
			prepareListOfServers(data);
			setShowLoginForm(false);
		}
		return;
	};

	//Запрос данных о пользователе 
	const SelfRequest = () => {
		return fetch(`${URL}/Self`, { credentials: 'include' })
			.then(response => response.json())
			.then(data => {
				// После получения данных из запроса, сохраняем нужные значения в локальное хранилище
				localStorage.setItem('name', data.name);
				localStorage.setItem('login', data.login);
			})
			.catch(error => {
				console.error('Ошибка при выполнении запроса:', error);
			});
	};

	// Добавление сервера с isDefault: true в начало списка
	const prepareListOfServers = (data) => {
		const defaultServer = data.find((server) => server.isDefault);
		setSelectedServer(defaultServer.id.toString());
		setServers(data);
		return servers;
	};

	return (
		<CssVarsProvider defaultMode="light" disableTransitionOnChange>
		<CssBaseline />
		<GlobalStyles
			styles={{
				':root': {
					'--Collapsed-breakpoint': '769px', 
					'--Cover-width': '50vw', 
					'--Form-maxWidth': '1000px',
				},
			}}
		/>
		<Box
			sx={(theme) => ({
				width:
					'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
				position: 'relative',
				zIndex: 1,
				display: 'flex',
				justifyContent: 'flex-end',
				backgroundColor: 'rgba(255 255 255)',
				[theme.getColorSchemeSelector('dark')]: {
					backgroundColor: 'rgba(0 0 0)',
				},
			})}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					minHeight: '100dvh',
					width:
						'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
					maxWidth: '100%',
					px: 2,
				}}
			>
				<Box
					component="header"
					sx={{
						pt: 3,
						display: 'flex',
						flexDirection: 'row-reverse',
						alignItems: 'center', 
						justifyContent: 'space-between',
					}}
				>
					<ColorSchemeToggle />
				</Box>
				<Box
					component="main"
					sx={{
						my: 'auto',
						py: 2,
						pb: 5,
						display: 'flex',
						flexDirection: 'column',
						gap: 1,
						width: 400,
						maxWidth: '100%',
						mx: 'auto',
						borderRadius: 'sm',
						'& form': {
							display: 'flex', 
							flexDirection: 'column',
							gap: 2,
						},
						[`& .${formLabelClasses.asterisk}`]: {
							visibility: 'hidden',
						},
					}}
				>
					<Box sx={{ mx: 'auto', gap: 2, display: 'flex', alignItems: 'center', pb: 9}}>
						<Logo/>
					</Box>

					<Stack gap={4} sx={{ mt: 1 }}>
						{showLoginForm && (
							<form
								onSubmit={(event: React.FormEvent<SignInFormElement>) => {
									event.preventDefault();
									const formElements = event.currentTarget.elements;
									login = formElements.login.value;
									password = formElements.password.value;
									checkLogin(login,password);
								}}
							>
								<FormControl required>
									<FormLabel>Login</FormLabel>
									<Input type="login" name="login" startDecorator={<LoginIcon sx={{ width:"20px" }}/>} />
								</FormControl>
								<FormControl required>
									<FormLabel>Password</FormLabel>
									<Input
										type={showPassword ? 'text' : 'password'}
										name="password"
										startDecorator={<PassIcon sx={{ width:"20px" }}/>}
										endDecorator={
											<IconButton
												onClick={() => setShowPassword((prev) => !prev)}
												aria-label="toggle password visibility"
											>
												{showPassword ? <OpenEyeIcon /> : <CloseEyeIcon />}
											</IconButton>
										}
									/>
								</FormControl>
								<Stack gap={4} sx={{ mt: 2 }}>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
										}}
									>
										{/* <Link level="title-sm" href="#replace-with-a-link">
											Forgot your password?
										</Link> */}
										
									</Box>
									<Button type="submit" fullWidth>
										Sign in
									</Button>
								</Stack>
							</form>
						)}
						{/* Отображение комбобокса, если серверов больше одного */}
						{!showLoginForm && (
							<form
								onSubmit={(event: React.FormEvent<SignInFormElement>) => {
									let storageId;
									if (typeof selectedServer === 'number') {
										storageId = selectedServer;
									} else {
										storageId =  parseInt(selectedServer, 10);
									}
									event.preventDefault();
									performSessionRequest(login, password, storageId);
								}}
							>
								<FormControl>
									<FormLabel>Choose Server</FormLabel>
									<Select
										defaultValue={selectedServer}
									>
										{servers.map((server:any) => (
											<Option key={server.id.toString()} value={server.id.toString()}>
												{server.name}
											</Option>
										))}
									</Select>
								</FormControl>
								<Button type="submit" fullWidth>
									Sign in
								</Button>
							</form>
						)}
					</Stack>
				</Box>
				<Box component="footer" sx={{ py: 3 }}>
					<Typography level="body-xs" textAlign="center">
					© Alaskar Technologies, Inc.{new Date().getFullYear()}
					</Typography>
				</Box>
			</Box>
		</Box>
		<Box
			sx={(theme) => ({
				height: '100%',
				position: 'fixed',
				right: 0,
				top: 0,
				bottom: 0,
				left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
				backgroundImage:
					'url(images/white_log_form.jpg)',
				[theme.getColorSchemeSelector('dark')]: {
					backgroundImage:
					'url(images/black_log_form.jpg)',
				},
			})}
		/>
		</CssVarsProvider>
	);
}