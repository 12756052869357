import * as React from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import { useNavigate } from 'react-router-dom';

export default function Development () {
	const navigate = useNavigate();
	const handleButtonClick = () => {
		navigate(`/${window.location.pathname.split('/')[1]}`);
		{/*window.open(`/${module}`, '_blank');*/}
	};

	return (
		<Box
			display="grid"
			justifyContent="center"
			alignItems="center"
			height="100vh"
			sx ={{
				gridTemplateRows: '40dvh 10dvh 10dvh 40dvh'
			}}
		>
			<Box></Box>
			<Typography level="h1">
				Модуль в разработке.
			</Typography>
			<Button
				onClick={() => handleButtonClick()}
			>
				Back
			</Button>
		</Box>
	);
};
