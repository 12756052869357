import * as React from 'react';
import {useNavigate, useLocation } from 'react-router-dom';

//import { useColorScheme } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import Menu from '@mui/joy/Menu';
import Link from '@mui/joy/Link';
import Stack from '@mui/joy/Stack';
import Avatar from '@mui/joy/Avatar';
//import Tooltip from '@mui/joy/Tooltip';
import Dropdown from '@mui/joy/Dropdown';
import MenuItem from '@mui/joy/MenuItem';
import Typography from '@mui/joy/Typography';
import MenuButton from '@mui/joy/MenuButton';
//import IconButton from '@mui/joy/IconButton';
import ListDivider from '@mui/joy/ListDivider';

import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
//import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
//import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';

import URL from '../../../../URl';
  
export default function Header() {
	const navigate = useNavigate();
	const location = useLocation();
	const pathParts = location.pathname.split('/');
	const pageTitle = camelCaseToWords(pathParts[pathParts.length - 1]);
  
	React.useEffect(() => {
		const asidCookie = document.cookie.split('; ').find((cookie) => cookie.startsWith('asid='));
		if (!asidCookie) {
			navigate('/SignIn');
			localStorage.removeItem('name');
			localStorage.removeItem('login');
		}
	}, []);
  	
	const handleLogout = async () => {
		try {
			const response = await fetch(`${URL}/Self`, { method: 'DELETE', credentials: 'include' });
			if (response.ok) {
				navigate('/SignIn');
				localStorage.removeItem('name');
				localStorage.removeItem('login');
			} else {
				console.error('Ошибка выполнения запроса');
			}
		} catch (error) {
			console.error('Произошла ошибка', error);
		}
	};
  
	function camelCaseToWords(camelCaseString) {
	  	return camelCaseString.replace(/([A-Z])/g, ' $1').trim();
	}
  
	return (
		<Box
			sx={{
				display: 'flex',
				flexGrow: 1,
				justifyContent: 'space-between',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					width:'50px'
				}}
			/>
			<Typography level="h2" color="neutral" textAlign ="center" sx={{ my: 2 }}>
				{pageTitle}
			</Typography>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					gap: 1.5,
					alignItems: 'center',
				}}
			>
				<Dropdown>
					<MenuButton
						variant="plain"
						size="sm"
						sx={{ maxWidth: '50px', maxHeight: '50px', borderRadius: '9999999px' }}
					>
						<Avatar sx={{ maxWidth: '50px', maxHeight: '50px' }} />
					</MenuButton>
					<Menu
						placement="bottom-end"
						size="sm"
						sx={{
							zIndex: '99999',
							p: 1,
							gap: 1,
							'--ListItem-radius': 'var(--joy-radius-sm)',
						}}
					>
						<MenuItem>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<Avatar sx={{ borderRadius: '50%' }} />
								<Box sx={{ ml: 1.5 }}>
									<Typography level="title-sm" textColor="text.primary">
										{localStorage.getItem('name')}
									</Typography>
									<Typography level="body-xs" textColor="text.tertiary">
										{localStorage.getItem('login')}
									</Typography>
								</Box>
							</Box>
						</MenuItem>
						<ListDivider />
						<Link href="https://docs.alaskar.tech" target="_blank" underline="none">
							<MenuItem>
								<HelpRoundedIcon />
								Help
							</MenuItem>
						</Link>
						<MenuItem>
							<SettingsRoundedIcon />
							Settings
						</MenuItem>
						<ListDivider />
						<MenuItem onClick={handleLogout}>
							<LogoutRoundedIcon />
							Log out
						</MenuItem>
					</Menu>
				</Dropdown>
			</Box>
		</Box>
	);
}