import * as React from 'react';
import Box from '@mui/joy/Box';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Button from '@mui/joy/Button';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Divider from '@mui/joy/Divider';
import FormLabel from '@mui/joy/FormLabel';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import ModalDialog from '@mui/joy/ModalDialog';
import IconButton, { iconButtonClasses } from '@mui/joy/IconButton';

import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import URL from '../../../../../../URl';

export default function OrderTable() {
	const[filter, setFilter] = React.useState([]);
	const [selected, setSelected] = React.useState({
	  	module: '',
	 	access: '',
	});
	const [open, setOpen] = React.useState(false);
	const [data, setData] = React.useState([]);
	const [currentPage, setCurrentPage] = React.useState(1);
	const rowsPerPage = 13;
  
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(`${URL}/Self/Permissions`, { credentials: 'include' });
				const result = await response.json();
				setData(result);
				setFilter(result);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};
	
		fetchData();
	}, []);
  
	const uniqueModules = Array.from(new Set(filter.map(row => (row as any).module)));
	const uniqueAccess = Array.from(new Set(filter.map(row => (row as any).access)));
	
	React.useEffect(() => {
		// Функция фильтрации данных при изменении выбранных значений
		const filteredData = data.filter((row) =>
		  (selected.module ? (row as any).module === selected.module : true) &&
		  (selected.access ? (row as any).access === selected.access : true)
		);
		// Обновление данных после фильтрации
		setData(filteredData);
	}, [selected]);

	const renderFilters = () => (
		<React.Fragment>
			<FormControl size="sm">
				<FormLabel>Module</FormLabel>
				<Select
					size="sm"
					defaultValue={""}
					onChange={(event) => setSelected({ ...selected, module: event?.target.textContent })}
				>
					<Option value="">All</Option>
					{uniqueModules.map((module, index) => (
						<Option key={index} value={module}>
							{module}
						</Option>
					))}
				</Select>
			</FormControl>
			<FormControl size="sm">
				<FormLabel>Access</FormLabel>
				<Select
					size="sm"
					defaultValue={""}
					onChange={(event) => setSelected({ ...selected, access: event?.target.textContent })}
				>
					<Option value="">All</Option>
					{uniqueAccess.map((access, index) => (
						<Option key={index} value={access}>
							{access}
						</Option>
					))}
				</Select>
			</FormControl>
		</React.Fragment>
	);
	
  
	const startIndex = (currentPage - 1) * rowsPerPage;
	const endIndex = startIndex + rowsPerPage;

	return (
		<React.Fragment>
			<Sheet
				className="SearchAndFilters-mobile"
				sx={{
					display: { xs: 'flex', sm: 'none' },
					my: 1,
					gap: 1,
				}}
			>
				<Input
					size="sm"
					placeholder="Search"
					startDecorator={<SearchIcon />}
					sx={{ flexGrow: 1 }}
				/>
				<IconButton
					size="sm"
					variant="outlined"
					color="neutral"
					onClick={() => setOpen(true)}
				>
					<FilterAltIcon />
				</IconButton>
				<Modal open={open} onClose={() => setOpen(false)}>
					<ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
						<ModalClose />
						<Typography id="filter-modal" level="h2">
							Filters
						</Typography>
						<Divider sx={{ my: 2 }} />
						<Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
							{renderFilters()}
							<Button color="primary" onClick={() => setOpen(false)}>
								Submit
							</Button>
						</Sheet>
					</ModalDialog>
				</Modal>
			</Sheet>
			<Box
				className="SearchAndFilters-tabletUp"
				sx={{
					borderRadius: 'sm',
					pb: 2,
					display: { xs: 'none', sm: 'flex' },
					flexWrap: 'wrap',
					gap: 1.5,
					'& > *': {
						minWidth: { xs: '120px', md: '160px' },
					},
				}}
			>
				<FormControl sx={{ flex: 1 }} size="sm">
					<FormLabel>Search for order</FormLabel>
					<Input size="sm" placeholder="Search" startDecorator={<SearchIcon />} />
				</FormControl>
				{renderFilters()}
			</Box>
			<Sheet
				className="OrderTableContainer"
				variant="outlined"
				sx={{
					display: { xs: 'none', sm: 'initial' },
					width: '100%',
					borderRadius: 'sm',
					flexShrink: 1,
					overflow: 'auto',
					minHeight: 0,
				}}
			>
				<Table
					aria-labelledby="tableTitle"
					stickyHeader
					hoverRow
					borderAxis="bothBetween"
					sx={{
						'--TableCell-headBackground': 'var(--joy-palette-background-level1)',
						'--Table-headerUnderlineThickness': '1px',
						'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
						'--TableCell-paddingY': '4px',
						'--TableCell-paddingX': '8px',
					}}
				>
					<thead>
						<tr>
							<th style={{ width: 140, padding: '12px 6px' }}>Module</th>
							<th style={{ width: 140, padding: '12px 6px' }}>Window</th>
							<th style={{ width: 240, padding: '12px 6px' }}>Access</th>
						</tr>
					</thead>
					<tbody>
						{
							data.slice(startIndex, endIndex)
							.map((row) => (
								<tr key={(row as any).yourUniqueKey}>
									<td>{(row as any).module}</td>
									<td>{(row as any).window}</td>
									<td>{(row as any).access}</td>
								</tr>
							))
						}
					</tbody>
				</Table>
			</Sheet>
			<Box
				className="Pagination-laptopUp"
				sx={{
					pt: 2,
					gap: 1,
					[`& .${iconButtonClasses.root}`]: { borderRadius: '50%' },
					display: {
						xs: 'none',
						md: 'flex',
					},
				}}
			>
				<Button
					size="sm"
					variant="outlined"
					color="neutral"
					startDecorator={<KeyboardArrowLeftIcon />}
					onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
				>
					Previous
				</Button>

				<Box sx={{ flex: 1 }} />
				{Array.from({ length: Math.ceil(data.length / rowsPerPage) }, (_, index) => (
					<IconButton
						key={index + 1}
						size="sm"
						variant={currentPage === index + 1 ? 'outlined' : 'plain'}
						color="neutral"
						onClick={() => setCurrentPage(index + 1)}
					>
						{index + 1}
					</IconButton>
				))}
				<Box sx={{ flex: 1 }} />

				<Button
					size="sm"
					variant="outlined"
					color="neutral"
					endDecorator={<KeyboardArrowRightIcon />}
					onClick={() =>
						setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(data.length / rowsPerPage)))
					}
				>
					Next
				</Button>
			</Box>
		</React.Fragment>
	);
}