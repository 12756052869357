import React from 'react';
import TeamExample from './TEST/App.tsx';
import Module from './components/Modules/Main.tsx';
import Development from './components/Development.tsx';
import Main from './components/Main_page/Main_page.tsx';
import JoySignInSideTemplate from './components/LoginForm.tsx';
import BaseSetup from './components/Modules/Modules/Store/BaseSetup/BaseSetup.tsx';
import Store from './components/Modules/Modules/Store/Store/Store.tsx';
import { BrowserRouter, Routes, Route, useNavigate} from 'react-router-dom';

function App() {
	// Проверка существования cookie с именем "asid"
	//const hasAsidCookie = document.cookie.split(';').some((item) => item.trim().startsWith('asid='));
	//const navigate = useNavigate();
	
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/SignIn" element={<JoySignInSideTemplate />} />
				<Route path="/test_main" element={<TeamExample/>} />
				<Route path="/" element={<Main/>}/>
				<Route path="/:module" element={<Module />} />
				<Route path="/:module/BaseSetup" element={<BaseSetup/>}/>
				<Route path="/:module/Store" element={<Store/>}/>
				<Route path="/:module/*" element={<Development/>}/>
			</Routes>
		</BrowserRouter>
	);
};

export default App;
