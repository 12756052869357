let currentURL = window.location.href;

let URL;

if (currentURL.includes('localhost')) {
  URL = 'http://localhost:8080';
} else if (currentURL.includes('alaskar')) {
  URL = 'https://testcwc.alaskar.tech:8080';
} else {
 //Другие Url
}

export default URL;
