import * as React from 'react';
import Autocomplete from '@mui/joy/Autocomplete';
import { styled } from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import Box from '@mui/joy/Box';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Checkbox from '@mui/joy/Checkbox';
import Chip from '@mui/joy/Chip';
import ChipDelete from '@mui/joy/ChipDelete';
import Typography from '@mui/joy/Typography';
import RadioGroup from '@mui/joy/RadioGroup';
import Radio from '@mui/joy/Radio';
import Slider from '@mui/joy/Slider';
import AccordionGroup from '@mui/joy/AccordionGroup';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails, {
  accordionDetailsClasses,
} from '@mui/joy/AccordionDetails';
import AccordionSummary, {
  accordionSummaryClasses,
} from '@mui/joy/AccordionSummary';

interface AddEditFormProps {
	selectedRowData: any;
}

export default function AddEditForm({ selectedRowData }: AddEditFormProps) {
	const [isEditor, setIsEditor] = React.useState(true)
	return (
		<Box sx={{ border: '2px solid grey',height:'94vh', }}>
			<Grid container spacing={0} sx={{ flexGrow: 1 }}>

				<Grid xs={8}>
					<FormControl sx={{mt:2, mx:1}}>
						<FormLabel>Selected Tag Number: </FormLabel>
						<Input disabled variant="solid" size="sm" value={selectedRowData?.batchNumber || ''}/>
					</FormControl>
				</Grid>
				<Grid xs={4}>
					<FormControl sx={{mt:2, mx:1}}>
						<FormLabel>Qty: </FormLabel>
						<Input disabled variant="solid" size="sm" value={selectedRowData?.qty || ''}/>
					</FormControl>
				</Grid>

				<Grid xs={12}>
					<FormControl sx={{m:1}}>
						<FormLabel>PN: </FormLabel>
						<Input size="sm" value={selectedRowData?.pn || ''}/>
					</FormControl>
				</Grid>

				<Grid xs={12}>
					<FormControl sx={{my:1, mx:1}}>
						<FormLabel>Description: </FormLabel>
						<Input disabled variant="solid" size="sm" value={selectedRowData?.description || ''}/>
					</FormControl>
				</Grid>

				<Grid xs={8}>
					<FormControl sx={{my:1, mx:1}}>
						<FormLabel>Condition: </FormLabel>
						<Input size="sm" value={selectedRowData?.condition || ''}/>
					</FormControl>
				</Grid>
				<Grid xs={4}>
					<FormControl sx={{my:1, mx:1}}>
						<FormLabel>Store Time (m): </FormLabel>
						<Input size="sm" value={selectedRowData?.storeTime || ''}/>
					</FormControl>
				</Grid>

				<Grid xs={8}>
					<FormControl sx={{my:1, mx:1}}>
						<FormLabel>Serial Number: </FormLabel>
						<Input size="sm"value={selectedRowData?.serialNumber || ''}/>
					</FormControl>
				</Grid>
				<Grid xs={4}>
					<FormControl sx={{my:1, mx:1}}>
						<FormLabel>MFG Date: </FormLabel>
						<Input size="sm" value={selectedRowData?.mfgDate || ''}/>
					</FormControl>
				</Grid>

				<Grid xs={5}>
					<FormControl sx={{my:1, mx:1}}>
						<FormLabel>Certificate Type: </FormLabel>
						<Input size="sm"value={selectedRowData?.certificateType || ''}/>
					</FormControl>
				</Grid>
				<Grid xs={7}>
					<FormControl sx={{my:1, mx:1}}>
						<FormLabel>Certificate Number: </FormLabel>
						<Input size="sm" value={selectedRowData?.certificateNumber || ''}/>
					</FormControl>
				</Grid>

				<Grid xs={5}>
					<FormControl sx={{my:1, mx:1}}>
						<FormLabel>Certificate Date: </FormLabel>
						<Input size="sm" value={selectedRowData?.certificateDate || ''}/>
					</FormControl>
				</Grid>
				<Grid xs={7}>
					<FormControl sx={{my:1, mx:1}}>
						<FormLabel>Approval Reference: </FormLabel>
						<Input size="sm" value={selectedRowData?.approvalReference || ''}/>
					</FormControl>
				</Grid>

				<Grid xs={2}>
					<FormControl sx={{my:1, ml:1, mr:0.5}}>
						<FormLabel>TSN: </FormLabel>
						<Input size="sm" value={selectedRowData?.tsn || ''}/>
					</FormControl>
				</Grid>
				<Grid xs={2}>
					<FormControl sx={{my:1, mx:0.5}}>
						<FormLabel>CSN: </FormLabel>
						<Input size="sm" value={selectedRowData?.csn || ''}/>
					</FormControl>
				</Grid>
				<Grid xs={2}>
					<FormControl sx={{my:1, mx:0.5}}>
						<FormLabel>TSOH: </FormLabel>
						<Input size="sm" value={selectedRowData?.tsoh || ''}/>
					</FormControl>
				</Grid>
				<Grid xs={2}>
					<FormControl sx={{my:1, mx:0.5}}>
						<FormLabel>CSOH: </FormLabel>
						<Input size="sm" value={selectedRowData?.csoh || ''}/>
					</FormControl>
				</Grid>
				<Grid xs={2}>
					<FormControl sx={{my:1, mx:0.5}}>
						<FormLabel>TSR: </FormLabel>
						<Input size="sm" value={selectedRowData?.tsr || ''}/>
					</FormControl>
				</Grid>
				<Grid xs={2}>
					<FormControl sx={{my:1, ml:0.5, mr:1}}>
						<FormLabel>CSR: </FormLabel>
						<Input size="sm" value={selectedRowData?.csr || ''}/>
					</FormControl>
				</Grid>

				<Grid xs={9}>
					<FormControl sx={{my:1, mx:1}}>
						<FormLabel>MOD Status/Remarks: </FormLabel>
						<Input size="sm" value={selectedRowData?.remarks || ''}/>
					</FormControl>
				</Grid>
				<Grid xs={3}>
					<FormControl sx={{mt:4, mx:1}}>
						<FormLabel/>
						<Checkbox label="IsPool" value={selectedRowData?.isPool || ''} />
					</FormControl>
				</Grid>

				<Grid xs={7}>
					<FormControl sx={{my:1, mx:1}}>
						<FormLabel>External Batch Number: </FormLabel>
						<Input size="sm" />
					</FormControl>
				</Grid>
				<Grid xs={5}>
					<FormControl sx={{my:1, mx:1}}>
						<FormLabel>Owner: </FormLabel>
						<Input size="sm" value={selectedRowData?.owner || ''}/>
					</FormControl>
				</Grid>

				<Grid xs={6}>
					<FormControl sx={{my:1, mx:1}}>
						<FormLabel>Store: </FormLabel>
						<Input size="sm" value={selectedRowData?.store || ''}/>
					</FormControl>
				</Grid>
				<Grid xs={6}>
					<FormControl sx={{my:1, mx:1}}>
						<FormLabel>Store Address: </FormLabel>
						<Input size="sm" value={selectedRowData?.storeAddress || ''}/>
					</FormControl>
				</Grid>

			</Grid>
		</Box>
	);
}