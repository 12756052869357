import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/joy/Box';
import Link from '@mui/joy/Link';
import List from '@mui/joy/List';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Typography from '@mui/joy/Typography';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import { CssVarsProvider } from '@mui/joy/styles';

import OpenEyeIcon from '@mui/icons-material/Visibility';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

import Header from '../Header.tsx';

import URL from '../../URl';

export default function Module() {
	const [pageNames, setPageNames] = React.useState([]);
	const [filteredPageNames, setFilteredPageNames] = React.useState([]);
	const [searchText, setSearchText] = React.useState('');
	const navigate = useNavigate();

	const handleButtonClick = (module: string) => {
		navigate(`/${window.location.pathname.split('/').pop()}/${module}`);
		{/*window.open(`/${module}`, '_blank');*/}
	};

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				// Получаем последнюю часть пути текущей страницы
				const moduleName = ((s:any) => s.replace(/([A-Z])/g, ' $1').trim())(window.location.pathname.split('/').pop());
				if (moduleName == 'All'){
					const response = await fetch(`${URL}/Self/Permissions`, {
						credentials: 'include',
						headers: {
						  'Content-Type': 'application/json',
						},
					  });
					const data = await response.json();
					setPageNames(data.map((data) => data.window));
				} else {
					const response = await fetch(`${URL}/Self/Permissions`, {
						method: 'POST',
						credentials: 'include',
						headers: {
						  'Content-Type': 'application/json',
						},
						body: JSON.stringify({ moduleName }),
					  });
					const data = await response.json();
					if (data.length === 0) {
						navigate('/');
					}
					setPageNames(data.map((data) => data.window));
				}
			} catch (error) {
				console.error('Error fetching permissions:', error);
			}
		};

		fetchData();
	}, []);

	React.useEffect(() => {
		// Фильтровать страницы по тексту поиска
		const filteredPages = pageNames.filter((page:any) => page.toLowerCase().includes(searchText.toLowerCase()));
		setFilteredPageNames(filteredPages);
	}, [pageNames, searchText]);

	return (
		<CssVarsProvider disableTransitionOnChange>
			<Box
				sx={{
					display: 'grid',
					gridTemplateRows: 'minmax(57px, 70px) 40px 1fr',
					minHeight: '100vh',
				}}
			>
				<Box
					sx={{
						p: 2,
						gap: 2,
						bgcolor: 'background.surface',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						gridColumn: '1 / -1',
						borderBottom: '1px solid',
						borderColor: 'divider',
						position: 'sticky',
						top: 0,
						zIndex: 1100,
					}}
				>
					<Header setSearchText={setSearchText} />
				</Box>
				<Box sx={{ display: 'absolute', alignItems: 'center' }}>
					<Breadcrumbs
						size="sm"
						aria-label="breadcrumbs"
						separator={<ChevronRightRoundedIcon/>}
						sx={{ pl: 0, mx: 2, my: 1 }}
					>
						<Link
							underline="none"
							color="neutral"
							href="/"
							aria-label="Home"
						>
							<HomeRoundedIcon />
						</Link>
						<Typography color="primary" fontWeight={500} fontSize={12}>
							{window.location.pathname.split('/').pop()}
						</Typography>
					</Breadcrumbs>
				</Box>
				<Box sx={{ width: '100%' }}>
					<List
						sx={{
						display: 'grid',
						gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
						gridAutoFlow: 'dense',
						gap: 5,
						alignItems: 'center',
						mx: '10%',
						}}
					>
						{filteredPageNames.map((data: any) => (
							
							<Button
								variant="outlined"
								color="neutral"
								key={data}
								sx={{
									height: '100px',
									borderRadius: '15px',
									boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.13)',
									fontSize: '18px',
									position: 'relative',
								}}
								onClick={() => handleButtonClick(data)}
							>
								{data}
								<span
									style={{
										position: 'absolute',
										bottom: '0',
										right: '0',
										marginRight: '10px',
									}}
								>
									<Tooltip title="Read only" arrow>
										<OpenEyeIcon />
									</Tooltip>
								</span>
							</Button>
						))}
					</List>
				</Box>
			</Box>
		</CssVarsProvider>
	);
}
